<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_postulaciones_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Postulaciones
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group">
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Bloque</label
                      >
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="filtros.bloque"
                        placeholder="Bloques"
                        label="nombre"
                        :options="listasForms.bloques"
                        style="font-size: 15px; background-color: #fff"
                        @input="selectBloque()"
                        multiple
                        class="form-control form-control-sm p-0 col-md-7"
                      ></v-select>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5" style="font-size: 20px"
                        >Transportadora</label
                      >
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="filtros.empresa"
                        placeholder="Empresa"
                        label="razon_social"
                        :options="listasForms.empresas"
                        style="font-size: 15px; background-color: #fff"
                        @input="selectEmpresas()"
                        multiple
                        class="form-control form-control-sm p-0 col-md-7"
                      ></v-select>
                    </div>
                    <div class="form-group row">
                      <label
                        class="col-md-5"
                        style="font-size: 20px"
                        for="fecha_inicial"
                        >Fecha Inicial</label
                      >
                      <input
                        type="date"
                        v-model="filtros.fecha_inicial"
                        class="form-control form-control-sm col-md-7"
                        @input="validaFechasForm"
                      />
                    </div>
                    <div class="form-group row">
                      <label
                        class="col-md-5"
                        style="font-size: 20px"
                        for="fecha_final"
                        >Fecha final</label
                      >

                      <input
                        type="date"
                        v-model="filtros.fecha_final"
                        class="form-control form-control-sm col-md-7"
                        @input="validaFechasForm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre"
                              >Nombre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.bloque"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Bloque</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_final_oferta"
                              v-model="form.transportadora"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_final_oferta"
                              >Transportadora</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="obvs"
                              v-model="form.obvs"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="obvs"
                              >Observacion</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="observaciones"
                              v-model="form.fecha_ini"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="observaciones"
                              >Fecha Inicial</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="planeaciones_volumenes"
                              v-model="form.fecha_fin"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="planeaciones_volumenes"
                              >Fecha Final</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('tif.postulaciones.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "TifPostulacionesExport", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      listasForms: {
        bloques: [],
        empresas: [],
      },
      filtros: {
        bloque: [],
        bloque_id: [],
        empresa: [],
        empresa_id: [],
        fecha_inicial: null,
        fecha_final: null,
      },
      form: {
        nombre: true,
        bloque: true,
        transportadora: true,
        fecha_ini: true,
        fecha_fin: true,
        obvs: true,
      },
    };
  },

  methods: {
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tif/postulaciones/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    selectBloque() {
      this.filtros.bloque_id = this.filtros.bloque.map((e) => e.id);
    },

    getTransportadoras() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    selectEmpresas() {
      this.filtros.empresa_id = this.filtros.empresa.map((e) => e.id);
    },

    validaFechasForm() {
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        const fecha_menor = new Date(this.filtros.fecha_inicial);
        const fecha_mayor = new Date(this.filtros.fecha_final);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
  },
  mounted() {
    this.getBloques();
    this.getTransportadoras();
  },
};
</script>
